<template>
    <mercur-card>
        <h2 class="font-weight-normal">HS Code configuration: {{productConfigurationTemplateName}}</h2>
        <mercur-input v-model="$v.harmonizationCode.$model">
            <template v-if="$v.harmonizationCode.$dirty" slot="note">
                <span class="error" v-if="!$v.harmonizationCode.required">Required</span>
                <span class="error" v-if="!$v.harmonizationCode.numeric">HS Codes needs to be a number</span>
                <span
                    class="error"
                    v-if="!$v.harmonizationCode.minLength || !$v.harmonizationCode.maxLength">
                    HS Codes needs to have between 7 to 12 numbers
                </span>
            </template>
        </mercur-input>
        <div class="row mt-4">
            <div class="col-6">
                <div class="border">
                    <div class="py-3 px-2 position-relative" style="background-color: #000; color: #fff;">
                        <mercur-button
                            v-if="Object.keys(exceptionsToAdd).length > 0"
                            @click="addExceptionToOverrides"
                            class="btn btn-icon btn-yellow btn-raised position-absolute add-exception-btn">
                            <i class="fas fa-plus" />
                        </mercur-button>
                        <h4 class="my-0 pl-1 text-white font-weight-normal">select attribute & options for exceptions</h4>
                    </div>
                    <div class="py-4 text-center" v-if="loadingAttributes">
                        <mercur-spinner class="mr-5"></mercur-spinner>
                    </div>
                    <div v-else style="overflow-y: auto; max-height: 55vh;">
                        <mercur-item v-for="(attributes, key) in productAttributes" :key="key" :expand="true">
                            <b>{{key}}</b>
                            <template slot="content">
                                <div class="p-2 border-top bg-grey-lighter">
                                    <mercur-chip
                                        class="my-1"
                                        :class="exceptionsToAdd[key] && exceptionsToAdd[key].some(e => e === attribute) ? 'bg-yellow text-black' : ''"
                                        v-for="(attribute, index) in attributes"
                                        :key="index"
                                        @click="prepareExceptions(attribute, key)"
                                        :clickable="true">
                                        {{attribute.option}}
                                    </mercur-chip>
                                </div>
                            </template>
                        </mercur-item>
                    </div>
                </div>
            </div>
            <div class="col-6">
                <h3 class="font-weight-normal mt-0 mb-2">Exceptions</h3>
                <small>When multiple exceptions are created, they will be attached top down, meaning when exception is found, ignore the following. Use the arrow to prioritise one over the other</small>
                <div class="py-4 text-center" v-if="loadingOverrides">
                    <mercur-spinner class="mr-5"></mercur-spinner>
                </div>
                <div v-else class="override-list mt-2">
                    <transition-group name="list" tag="div">
                        <div class="border mb-3 override-list-item position-relative" v-for="(override, key) in existingOverrides" :key="override.importance">
                            <mercur-button
                                @click="removeException(key)"
                                class="btn btn-icon btn-icon-sm btn-primary position-absolute override-list-item-delete">
                                <i class="fas fa-times"/>
                            </mercur-button>
                            <div class="p-2 d-flex justify-content-between bg-grey-lighter">
                                <div class="override-list-item-title">
                                    <div class="d-inline mr-1" v-for="(attributes, attributeType, index) in override.attributes" :key="override.importance+index">
                                        <small><strong>{{attributeType}}: </strong></small>
                                        <small><span v-for="(attribute) in attributes" :key="attribute.option">{{attribute.option}} </span></small>
                                    </div>
                                </div>
                                <div>
                                    <mercur-button v-if="key > 0" @click="changeImportance(key, 'UP')" class="btn btn-icon btn-icon-sm"><i class="fas fa-arrow-up" /></mercur-button>
                                    <mercur-button v-if="key < existingOverrides.length - 1" @click="changeImportance(key, 'DOWN')" class="btn btn-icon btn-icon-sm"><i class="fas fa-arrow-down" /></mercur-button>
                                </div>
                            </div>
                            <div class="px-2 override-list-input">
                                <mercur-input class="mt-3" placeholder="Add Hs Code" v-model="$v.existingOverrides.$each[key].harmonizationCode.$model">
                                    <template v-if="$v.existingOverrides.$each[key].$dirty" slot="note">
                                        <span class="error" v-if="!$v.existingOverrides.$each[key].harmonizationCode.required">Required</span>
                                        <span class="error" v-if="!$v.existingOverrides.$each[key].harmonizationCode.numeric">
                                            HS Codes needs to be a number
                                        </span>
                                        <span
                                            class="error"
                                            v-if="!$v.existingOverrides.$each[key].harmonizationCode.minLength || !$v.existingOverrides.$each[key].harmonizationCode.maxLength">
                                            HS Codes needs to have between 7 to 12 numbers
                                        </span>
                                    </template>
                                </mercur-input>
                            </div>
                        </div>
                    </transition-group>
                </div>
            </div>
        </div>
        <div class="align-right my-3">
            <mercur-button class="btn btn-yellow"
                :disabled="$v.$invalid"
                @click="saveHsCodeTemplate">
                PUBLISH CHANGES
            </mercur-button>
        </div>
  </mercur-card>
</template>

<script>
import CONFIG from '@root/config'
import { required, numeric, minLength, maxLength } from 'vuelidate/lib/validators'

export default {
    name: 'HsCodesProductTemplateEdit',
    data () {
        return {
            templateConfigurationType: this.$route.params.templateConfigurationType,
            getUrl: CONFIG.API.ROUTES.HS_CODES.GET,
            addUrl: CONFIG.API.ROUTES.HS_CODES.ADD,
            productConfigurationTemplateId: this.$route.params.productConfigurationTemplateId,
            productConfigurationTemplateName: this.$route.params.productConfigurationTemplateName,
            productHsCodeId: '',
            harmonizationCode: '',
            productAttributes: {},
            exceptionsToAdd: {},
            existingOverrides: [],
            overrideHsCode: '',
            loadingAttributes: false,
            loadingOverrides: false,
        }
    },
    validations () {
        return {
            harmonizationCode: {
                required,
                numeric,
                minLength: minLength(7),
                maxLength: maxLength(12),
            },
            existingOverrides: {
                $each: {
                    harmonizationCode: {
                        numeric,
                        required,
                        minLength: minLength(7),
                        maxLength: maxLength(12),
                    },
                },
            },
        }
    },
    methods: {
        changeImportance (importance, direction) {
            const selectedOverride = this.existingOverrides[importance]
            this.existingOverrides.splice(importance, 1)
            this.existingOverrides.splice(direction === 'UP' ? importance - 1 : importance + 1, 0, selectedOverride)
        },
        removeException (exceptionKey) {
            this.existingOverrides.splice(exceptionKey, 1)
        },
        prepareExceptions (attribute, key) {
            if (this.exceptionsToAdd[key]) {
                if (this.exceptionsToAdd[key].some(existingAttribute => existingAttribute === attribute)) {
                    this.exceptionsToAdd[key] = this.exceptionsToAdd[key].filter(existingAttribute => existingAttribute !== attribute)
                    this.$set(this, 'exceptionsToAdd', this.exceptionsToAdd)
                } else {
                    this.exceptionsToAdd[key].push(attribute)
                    this.$set(this, 'exceptionsToAdd', this.exceptionsToAdd)
                }
            } else {
                this.$set(this.exceptionsToAdd, key, [attribute])
            }
            if (this.exceptionsToAdd[key].length === 0) {
                delete this.exceptionsToAdd[key]
                this.$set(this, 'exceptionsToAdd', this.exceptionsToAdd)
            }
        },
        addExceptionToOverrides () {
            const importance = this.existingOverrides.length
            const exception = {
                harmonizationCode: '',
                importance: importance,
                attributes: this.exceptionsToAdd,
            }
            this.existingOverrides.push(exception)
            this.exceptionsToAdd = {}
        },
        getProductTemplate () {
            const url = this.getUrl.replace('{productConfigurationTemplateId}', this.productConfigurationTemplateId)
            this.addJob(url)
            this.loadingOverrides = true
            this.$api.post(url).then(({ data }) => {
                this.productConfigurationTemplateName = data.data.productHsCode.productConfigurationTemplateName
                this.productConfigurationTemplateId = data.data.productHsCode.productConfigurationTemplateId
                this.productHsCodeId = data.data.productHsCode.productHsCodeId
                this.harmonizationCode = data.data.productHsCode.harmonizationCode
                this.existingOverrides = data.data.productHsCode.overrides
            }).catch(err => {
                this.$root.$emit('notification:global', {
                    message: `Getting existing exceptions has failed`,
                    type: 'error',
                    errors: err,
                })
            }).finally(() => {
                this.finishJob(url)
                this.loadingOverrides = false
            })
        },
        getProductAttributes () {
            this.loadingAttributes = true
            this.$store.dispatch('products/getProductDetailsForSupplierAndProductConfigurationId', {
                supplierId: this.supplierId,
                productConfigurationId: this.productConfigurationTemplateId,
            }).then(product => {
                this.productAttributes = product.attributeConfiguration.selectedAttributes
                this.loadingAttributes = false
            }).catch(err => {
                this.$root.$emit('notification:global', {
                    message: `Getting existing exceptions has failed`,
                    type: 'error',
                    errors: err,
                })
            }).finally(() => {
                if (this.templateConfigurationType === 'EDIT') {
                    this.getProductTemplate()
                }
                this.loadingOverrides = false
            })
        },
        saveHsCodeTemplate () {
            this.loadingOverrides = true
            this.loadingAttributes = true
            this.existingOverrides.map((override, key) => { override.importance = key; return override })
            const payload = {
                productConfigurationTemplateId: this.productConfigurationTemplateId,
                productHsCodeId: this.productHsCodeId,
                harmonizationCode: this.harmonizationCode,
                overrides: this.existingOverrides,
            }
            let url = CONFIG.API.ROUTES.HS_CODES.UPDATE.replace('{productConfigurationTemplateId}', this.productConfigurationTemplateId)
            if (this.templateConfigurationType === 'ADD') {
                url = this.addUrl.replace('{productConfigurationTemplateId}', this.productConfigurationTemplateId)
                delete payload.productHsCodeId
            }
            this.addJob(url)
            this.$api.post(url, payload).then(() => {
                this.$root.$emit('notification:global', {
                    message: `Hs Code configuration has been updated`,
                    type: 'success',
                })
            }).catch(err => {
                this.$root.$emit('notification:global', {
                    message: `Getting existing exceptions has failed`,
                    type: 'error',
                    errors: err,
                })
            }).finally(() => {
                this.finishJob(url)
                this.getProductTemplate()
                this.templateConfigurationType = 'EDIT'
                this.loadingAttributes = false
            })
        },
    },
    created () {
        this.getProductAttributes()
    },
}
</script>
<style lang="scss">
    .add-exception-btn {
        right: -20px;
        top: -40%;
    }
    .override-list {
        .btn {
            margin-left: 0;
        }
        &-item {
            background-color: #fff;
            &-delete {
                right: -12px;
                top: -12px;
            }
            &-title {
                max-width: calc(100% - 80px)
            }
        }
    }
    .list-move {
        transition: transform .5s;
    }
</style>
